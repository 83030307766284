let resumeData = {
    "name": "EONO Logan",
    "role": "Alternant Développeur Fullstack",
    "socialLinks": [
        {
            "name": "linkedin",
            "url": "https://www.linkedin.com/in/logan-eono-83b08a227/?originalSubdomain=fr",
            "className": "fa fa-linkedin"
        },
        {
            "name": "bitbucket",
            "url": "https://bitbucket.org/neksooo/workspace/overview/",
            "className": "fa fa-bitbucket"
        },
    ],
    "aboutme": "Je m'appelle Logan EONO, j'ai 22 ans et je suis en alternance chez Epsilon Informatique. En" +
        " parallèle, j'effectue ma formation de MBA Développeur FullStack au sein de l'école" +
        " MyDigitalSchool situé sur Vannes.",
    "bac5": [
        {
            "UniversityName": "MyDigitalSchool - Vannes",
            "specialization": "MBA Développeur Full Stack",
            "MonthOfPassing": "2023 - 2025",
            "Achievements": "Titre RNCP Manager de Projet Web Digital"
        },
    ],
    "bac3": [
        {
            "UniversityName": "MyDigitalSchool - Vannes",
            "specialization": "Bachelor Développeur Web",
            "achievement": "Mention Bien",
            "MonthOfPassing": "2022 - 2023",
            "Achievements": "Titre RNCP Concepteur Développeur d'Applications"
        },
    ],
    "bac2": [
        {
            "UniversityName": "Lycée Rabelais - Saint-Brieuc",
            "specialization": "BTS Services Informatiques aux Organistaions",
            "MonthOfPassing": "2020 - 2022",
            "Achievements": "Solutions Logicielles et Applications Métiers (SLAM)"
        },
    ],
    "bac": [
        {
            "UniversityName": "Lycée Sacré Coeur - Saint-Brieuc",
            "specialization": "BAC PRO Systèmes Numériques",
            "MonthOfPassing": "2017 - 2020",
            "Achievements": "Réseaux Informatiques et Systèmes Communicants (RISC)"
        },
    ],
}

export default resumeData