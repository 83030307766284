import React, {Component} from 'react';

export default class Languages extends Component {
    render() {
        return (
            <section id="languages">
                <div className="row">
                    <div className="twelve columns collapsed">
                        <h1>Technologies et languages</h1>
                        <div id="languages-wrapper" className="bgrid-quarters s-bgrid-thirds cf">
                            <div className="row">
                                <div className="image-list">
                                    <img src="/images/languages/php.png" alt="" className="rounded-image"/>
                                    <img src="/images/languages/symfony.png" alt="" className="rounded-image"/>
                                    <img src="/images/languages/api_platform.png" alt="" className="rounded-image"/>
                                    <img src="/images/languages/react.png" alt="" className="rounded-image"/>
                                    <img src="/images/languages/nextjs.png" alt="" className="rounded-image"/>
                                    <img src="/images/languages/linux.png" alt="" className="rounded-image"/>
                                    <img src="/images/languages/mysql.png" alt="" className="rounded-image"/>
                                    <img src="/images/languages/doctrine.png" alt="" className="rounded-image"/>
                                    <img src="/images/languages/heidi.png" alt="" className="rounded-image"/>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        );
    }
}