import React, {Component} from 'react';

export default class Header extends Component {
    render() {
        let resumeData = this.props.resumeData;
        return (
            <React.Fragment>
                <header id="home">
                    <nav id="nav-wrap">
                        <a className="mobile-btn" href="#nav-wrap" title="Show navigation">Show navigation</a>
                        <a className="mobile-btn" href="#!" title="Hide navigation">Hide navigation</a>
                        <ul id="nav" className="nav">
                            <li className="current"><a className="smoothscroll" href="#home">Accueil</a></li>
                            <li><a className="smoothscroll" href="#about">Qui suis-je ?</a></li>
                            <li><a className="smoothscroll" href="#courses">Formations</a></li>
                            <li><a className="smoothscroll" href="#languages">Technologies et langages</a></li>
                        </ul>
                    </nav>

                    <div className="row banner">
                        <div className="banner-text">
                            <h1 className="responsive-headline">{resumeData.name}</h1>
                            <h3 style={{color: '#fff', fontFamily: 'Nunito'}}>{resumeData.role}
                            </h3>
                            <a href="https://epsilon-informatique.fr/" rel="noopener noreferrer" target="_blank">
                                <img className="eif" src="/images/eif.jpg" alt=""/>
                            </a>
                            <a href="https://www.mydigitalschool.com/" rel="noopener noreferrer" target="_blank">
                                <img className="mds" src="/images/mds.jpg" alt=""/>
                            </a>
                            <hr/>
                            <ul className="social">
                                {
                                    resumeData.socialLinks && resumeData.socialLinks.map((item, index) => {
                                            return (
                                                <li key={index}>
                                                    <a href={item.url} rel="noopener noreferrer" target="_blank"><i
                                                        className={item.className}></i></a>
                                                </li>
                                            )
                                        }
                                    )
                                }
                            </ul>
                        </div>
                    </div>

                    <p className="scrolldown">
                        <a className="smoothscroll" href="#about"><i className="icon-down-circle"></i></a>
                    </p>

                </header>
            </React.Fragment>
        );
    }
}